<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة نقطة ضعف">
          <b-row>
            <b-col md="" xl="2">
              <b-form-group>
                <b-form-checkbox v-model="terms2" plain class="custom-control-warning">
                  <label> نقطة ضعف اساسية</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="terms2">
            <b-col md="1" xl="2">
              <b-form-group label-for="delimiter">
                <label> نقطة الضعف الاساسية</label>
                <b-form-input :disabled="isDisabled2" v-model="weak" id="input-default" />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addweak">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> اضافة النقطة</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="" xl="2">
              <b-form-group>
                <b-form-checkbox v-model="terms" plain class="custom-control-warning">
                  <label> نقطة ضعف فرعية</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="terms">
            <b-col md="6" xl="4">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> نقطة الضعف الاساسية</label>
                <v-select
                  :disabled="isDisabled"
                  v-model="selectedWeak"
                  :reduce="(val) => val.value"
                  :options="optionWeakness"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="1" xl="3">
              <b-form-group label-for="delimiter">
                <label> نقطة الضعف الفرعية</label>
                <b-form-input
                  :disabled="isDisabled"
                  v-model="selectedweak2"
                  id="input-default"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addsubweak">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> اضافة النقطة</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" نقاط الضعف">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
            <!-- <label>بحث</label> -->
            <div>
              <b-form-input
                v-model="searchTerm"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد نقاط الضعف</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-weakpoint',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deleteWeak(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import useList from "./List";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeMask } from "./code";
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
   BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    ToastificationContent,
    BCol,
  
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,

  },
setup(){
  const {
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useList()
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }

},
  data() {
    return {
      optionWeakness: [],
      selectedWeak: "",
      terms2: false,
      terms: false,
      selectedweak2: "",
      weak: "",

      codeMask,
      pageLength: 3,
      dir: false,

      columns: [
        {
          label: "نقطة الضعف",
          field: "name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
    };
  },

  computed: {
    isDisabled: function () {
      return !this.terms;
    },
    isDisabled2: function () {
      return !this.terms2;
    },
  },

  created() {
    this.getWeak();
    // this.getallWeak()
  },

  methods: {
    addweak() {
      let data = {
        name: this.weak,
      };
      //console.log(data);
      this.$http.post("/api/v1/weaknesses", data).then((res) => {
        //console.log(res);
        // this.getWeak()
        this.refetchData();

        this.weak=''
            this.$swal({
                       title: "تم الإضافة  بنجاح",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
        
      });
    },
    addsubweak() {
      let data = {
        parent_id: this.selectedWeak,
        name: this.selectedweak2,
      };
      //console.log(data);

      this.$http.post("/api/v1/sub_weaknesses", data).then((res) => {
        //console.log(res);
        // this.getWeak()
        this.refetchData();

        this.selectedWeak=''
                  this.selectedweak2=''
         this.$swal({
                       title: "تم الإضافة  بنجاح",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                
      });
    },
    deleteWeak(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/weaknesses/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            // this.getWeak();
            this.refetchData();

             this.$swal({
                      title: "تم الحذف  بنجاح",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
          });
        }
      });
    },
    getWeak() {
      this.$http.get("/api/v1/weaknesses").then((res) => {
        //console.log(res);
        let Weak = res.data.data.weaknesses;
        this.rows = Weak;
        //console.log(Weak);
        Weak.forEach((el) => {
          //  //console.log(el.name)
          this.optionWeakness.push({ label: el.name, value: el.id });
        });
      });
    },
    getallWeak() {
      this.options = [];
      this.$http.get("/api/weakness").then((res) => {
        let subweak = res.data.data.subweakness;
        let Weak = res.data.data.weakness;
        //console.log("weak", Weak);
        //console.log("subweak", subweak);
        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.name;
          subweak.map((ele, index) => {
            if (el.id == ele.weakness_id) {
              obj.children.push({ label: subweak[index].name, id: ele.name });
            }
          });
          this.options.push(obj);
          //console.log(("obj", obj));
        });
      });
    },
    //
  },
};
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>